<template>
	<div style="background-color: #f6f6f6; min-height: 100vh">
		<div v-if="isShowQR" class="text-description">
			<button class="model-button" @click.stop="closeQR">
				<div ref="qrimg" id="qrimg" style="width: 280px; height: 440px; margin-left: calc(50vw - 140px)">
					<qrcodeview></qrcodeview>
				</div>
				<button class="save-qr-button" @click="getQRCode">点击保存二维码</button>
			</button>
		</div>
		<div class="top-content">
			<img src="@/assets/image/mine/bg_top.png" alt="" class="logo-img" />
			<img :src="logo" class="logo" />
			<p class="user-name">{{ name }}</p>
			<img src="@/assets/image/mine/bg_amount.png" class="bg-amount" />
			<div style="margin-top: -92px; margin-left: 40px">
				<p class="amount-text">
					{{ balance }}
					<van-button class="cash-button" @click.native="toCash">去提现</van-button>
				</p>
				<p class="amount-title">账户余额(元)</p>
			</div>
		</div>
		<div style="height: 42px"></div>
		<div class="list-content">
			<el-row style="background-color: #f6f6f6">
				<el-col :xs="24" :sm="24" @click.native="showInfo">
					<div class="list-item-box top-item">
						<div class="item-content">
							<img src="@/assets/image/mine/icon_info.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">基本信息</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" @click.native="showBankInfo">
					<div class="list-item-box">
						<div class="item-content">
							<img src="@/assets/image/mine/icon_bank.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">银行账户</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" @click.native="showNewPassword">
					<div class="list-item-box">
						<div class="item-content">
							<img src="@/assets/image/tradelist/icon_sort.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">修改密码</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" v-if="is_temple" @click.native="showMerchants">
					<div class="list-item-box">
						<div class="item-content">
							<img src="@/assets/image/mine/icon_merchant.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">我的商户</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" v-if="is_temple" @click.native="showApprove">
					<div class="list-item-box">
						<div class="item-content">
							<img src="@/assets/image/mine/icon_approve.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">商户审核</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" v-if="is_temple" @click.native="showInviteQR">
					<div class="list-item-box">
						<div class="item-content">
							<img src="@/assets/image/mine/icon_qr.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">邀请码</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>

				<el-col :xs="24" :sm="24" @click.native="showQR">
					<div class="list-item-box last-item">
						<div class="item-content">
							<img src="@/assets/image/mine/icon_qr.png" class="list-icon-style" />

							<div class="word-area">
								<span style="font-size: 15px; margin-left: 8px">收款码</span>
							</div>
						</div>
						<img src="@/assets/image/mine/icon_arrow_right.png" class="list-icon-arrow" />
					</div>
				</el-col>
			</el-row>
			<button class="log-out-button" @click="logout">退&emsp;出&emsp;登&emsp;录</button>
			<van-dialog />
		</div>

		<van-tabbar route active-color="#333333" inactive-color="#eeeeee">
			<van-tabbar-item v-for="(item, index) in tabbar_items" :key="index" :to="item.src"
				>{{ item.title }}
				<template slot="icon" slot-scope="props">
					<img :src="props.active ? item.active : item.normal" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import qrcodeview from '@/views/h5/qrcode_view'
	import html2canvas from 'html2canvas'
	import { Dialog } from 'vant'

	export default {
		name: 'mine',
		components: {
			qrcodeview,
			[Dialog.Component.name]: Dialog.Component
		},
		data() {
			return {
				tabbar_items: [
					{
						title: '首页',
						normal: require('../../assets/image/tab/icon_home_normal.png'),
						active: require('../../assets/image/tab/icon_home_active.png'),
						src: '/h5/home'
					},
					{
						title: '我的',
						normal: require('../../assets/image/tab/icon_mine_normal.png'),
						active: require('../../assets/image/tab/icon_mine_active.png'),
						src: '/h5/mine'
					}
				],
				user_all_info: '',
				balance: '',
				name: '',
				is_temple: false,
				logo: require('../../assets/image/mine/logo.png'),
				merLogo: ''
			}
		},
		created() {
			document.title = '我的'
		},
		mounted() {
			this.getDetailData()
			this.getBalanceData()
			// console.log(typeof localStorage.getItem('type'))
			this.is_temple = localStorage.getItem('type') === '2' || localStorage.getItem('type') === '1'
			let merchantLogo = localStorage.getItem('merchantLogo')
			// merchantLogo = null
			// console.log(merchantLogo)
			if (merchantLogo != 'null') {
				this.logo = merchantLogo
			} else {
				this.logo = require('../../assets/image/mine/logo.png')
			}
		},
		methods: {
			toCash() {
				this.$router.push({ path: '/h5/getcash' })
			},
			showNewPassword() {
				this.$router.push({ path: '/h5/new_password' })
			},
			logout() {
				Dialog.confirm({
					title: '提示',
					message: '是否退出登录?'
				})
					.then(() => {
						this.$store.commit('logout')
						this.$router.push({ path: '/h5/login' })
					})
					.catch(() => {
						// on cancel
					})
			},
			showInviteQR() {
				localStorage.setItem('is_temple', 'true')
				this.$store.commit('inputQRView', true)
			},
			getQRCode() {
				setTimeout(function () {}, 100)
				html2canvas(document.querySelector('#qrimg'), { userCORS: true, allowTaint: false }).then(canvas => {
					let uri = canvas.toDataURL('image/png')
					// 转成图片，生成图片地址
					let eleLink = document.createElement('a')
					eleLink.href = uri // 转换后的图片地址
					eleLink.download = localStorage.getItem('merchantName')
					document.body.appendChild(eleLink)
					// 触发点击
					eleLink.click()
					// 然后移除
					document.body.removeChild(eleLink)
				})
			},
			showQR() {
				localStorage.setItem('is_temple', 'false')
				this.$store.commit('inputQRView', true)
			},
			closeQR() {
				this.$store.commit('inputQRView', false)
			},
			showInfo() {
				this.$router.push({ path: '/h5/mine/base', query: this.user_all_info })
			},
			showBankInfo() {
				this.$router.push({ path: '/h5/mine/bankinfo', query: this.user_all_info })
			},

			showMerchants() {
				this.$router.push({ path: '/h5/mine/merchants' })
			},
			showApprove() {
				this.$router.push({ path: '/h5/mine/approve' })
			},
			getDetailData() {
				//
				let _this = this
				Http.get('/frontend/merchant/getMerchantDetail')
					.then(result => {
						if (result && result.code === 200) {
							_this.user_all_info = result.data
							_this.name = localStorage.getItem('merchantName')
							// console.log(result)
						} else {
							_this.$message.error(result.message)
						}
					})
					.catch(error => {
						this.$message.error('请求失败')
					})
			},

			getBalanceData() {
				//
				let _this = this
				Http.get('/frontend/merchant/getBalance')
					.then(result => {
						if (result && result.code === 200) {
							_this.balance = result.data.balance
							// console.log(result)
						} else {
							_this.$message.error(result.message)
						}
					})
					.catch(error => {
						this.$message.error('请求失败')
					})
			}
		},
		computed: {
			isShowQR() {
				return this.$store.state.showQR
			}
		}
	}
</script>

<style scoped>
	.log-out-button {
		display: block;
		width: 345px;
		height: 42px;
		background: #eee5cd;
		border-radius: 21px;
		margin: 50px auto;
		border: none;
		color: #ad8e30;
	}

	.logo-img {
		width: 100%;
		height: calc(100vw * 605 / 750);
	}

	.logo {
		width: 80px;
		height: 80px;
		margin-top: 44px;
		margin-left: calc(-50vw - 40px);
		position: absolute;
		border-radius: 40px;
	}

	.user-name {
		width: 100vw;
		text-align: center;
		font-size: 21px;
		font-weight: 500;
		margin-top: calc(128px - (100vw * 605 / 750));
	}

	.bg-amount {
		display: block;
		width: calc(690 / 750 * 100vw);
		max-width: calc(690 / 750 * 750px);
		height: calc(220 / 750 * 100vw);
		max-height: calc(220 / 750 * 750px);
		margin: 0 auto;
	}

	.amount-title {
		width: 135px;
		height: 13px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 40px;
		opacity: 0.6;
		margin-bottom: 0px;
	}

	.amount-text {
		width: 228px;
		height: 46px;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
		margin-bottom: 0px;
	}

	.cash-button {
		top: -10px;
		width: 69px;
		height: 20px;
		margin-left: 2px;
		font-size: 12px;
		background: rgba(238, 229, 205, 0);
		border: 1px solid #2473c5;
		opacity: 0.7;
		border-radius: 10px;
	}

	.list-content {
		width: calc(690 / 750 * 100vw);
		max-width: calc(690 / 750 * 750px);
		margin: 15px auto;
		border-radius: 4px;
		height: auto;
		overflow: hidden;
		padding-bottom: 60px;
		/*border: 3px solid #BADA55;*/
	}

	.list-item-box {
		margin: 0;

		/*border-radius: 4px;*/
		padding-left: 8px;
		height: 64px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.1);
		display: flex;
		background-color: #ffffff;
	}

	.top-item {
		border-radius: 4px 4px 0 0;
	}

	.last-item {
		border-radius: 0 0 4px 4px;
	}

	.item-content {
		width: 90%;
		height: 64px;
	}

	.list-icon-style {
		width: 18px;
		height: 18px;
		object-fit: contain;
		margin-top: 25px;
		margin-left: 12px;
	}

	.list-icon-arrow {
		width: 6px;
		height: 11px;
		margin-top: 27px;
	}

	.word-area {
		height: 50px;
		margin: -21px 0px 0px 30px;
		/*padding: 20px 15px 20px 15px;*/
	}

	.text-description {
		position: fixed;
		top: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.199);
		z-index: 2000;
	}

	.model-button {
		height: 100%;
		width: 100%;
		background-color: transparent;
		z-index: 1999;
		border: none;
		padding: 0;
	}

	.save-qr-button {
		width: 100%;
		/*height: 40px;*/
		border: none;
		background-color: transparent;
		margin-top: 100px;
		font-size: 15px;
		font-family: Alibaba PuHuiTi;
		font-weight: 400;
		color: #ffffff;
		line-height: 20px;
		opacity: 0.8;
	}
</style>
